<template>
	<div v-if="isShow" class="import-shortcut" @click="showImportProgressModal">
		<div class="position-relative">{{ title }}</div>
		<CProgress
			:value="counter"
			:max="total"
			size="sm"
			color="success"
			class="mx-auto progress-size"
			animated
		/>
	</div>
</template>

<script>
export default {
	name: "ImportShortcut",

	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		total: {
			type: [String, Number],
			default: 100,
		},
		counter: {
			type: [String, Number],
			default: 0,
		},
		title: {
			type: String,
			default: "",
		},
	},

	methods: {
		showImportProgressModal() {
			this.$emit("show-import")
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";
.import-shortcut {
	position: fixed;
	right: 15px;
	bottom: 15px;
	min-width: 300px;
	background: $color-white;
	border-radius: 0.25rem;
	text-align: center;
	color: $color-dark-blue3;
	min-height: 50px;
	padding: 15px 15px;
	font-weight: 500;
	border: 1px solid $color-hawkes-blue;
	z-index: 100;

	&:hover {
		cursor: pointer;
	}
}
</style>
